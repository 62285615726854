import './App.css'

import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import { createContext, useState } from 'react'

import { AdminRouter } from './pages/Admin'
import { ApiKeysPage } from './pages/ApiKeys'
import { Dashboard } from './pages/Dashboard'
import LoadingPage from './LoadingPage'
import { LogoutPage } from './pages/Logout/LogoutPage'
import { ProfilePage } from './pages/Profile'
import { Project } from './pages/Project'
import { RedirectToLogin } from './pages/RedirectToLogin'
import { Report } from './pages/Report'
import { Shell } from './components/Shell'
import StartPage from './pages/StartPage'
import { SubscriptionPage } from './pages/Subscription'
import { featuresEnabled } from './services'
import { useAuth0 } from '@auth0/auth0-react'

export const AuthContext = createContext()

// We use this context to control the visibility of the welcome modal
// and it allows us to open and close the modal from anywhere in the app
export const GettingStartedContext = createContext()

const App = () => {
  // We store the welcome modal state in local storage so that it persists
  // the welcome model points to some documentation examples on how to get started.
  const welcomeModalSkipped =
    window.localStorage.getItem('welcomeModalSkipped') !== 'true'

  const [open, setOpen] = useState(welcomeModalSkipped)
  
  const auth0 = useAuth0()
  // useZendesk({ key: "7639035f-fcd3-4945-9f72-69d9406876e8" })
  const { isAuthenticated, isLoading } = auth0

  return (
    <GettingStartedContext.Provider value={{open, setOpen}}>
    <AuthContext.Provider value={auth0}>
      <Router>
        {isLoading ? (
          <Shell>
            <Route path="/" component={LoadingPage} />
          </Shell>
        ) : isAuthenticated ? (
          <Shell>
            <Switch>
              <Route path="/projects/:projectId" component={Project} />
              <Route path="/campaigns/:campaignId" component={Report} />
              <Route path="/projects" component={Dashboard} />
              <Route path="/api-keys" component={ApiKeysPage}>
                <Redirect to="/keys" />
              </Route>
              <Route path="/keys" component={ApiKeysPage} />
              <Route path="/profile" component={ProfilePage} />
              {featuresEnabled.stripeSubscriptions ? (
                <Route path="/subscription" component={SubscriptionPage} />
              ) : undefined}
              <Route path="/logout" component={LogoutPage} />
              <Route path="/admin" component={AdminRouter} />
              <Route path="/login">
                <Redirect to="/" />
              </Route>
              <Route path="/" component={Dashboard} />
            </Switch>
          </Shell>
        ) : (
          <Switch>
            <Route
              path="/login"
              render={() => (
                <Shell>
                  <RedirectToLogin />
                </Shell>
              )}
            />
            <Route
              path="/campaigns/:campaignId"
              render={() => (
                <Shell>
                  <Report />
                </Shell>
              )}
            />
            <Route path="/" render={() => <StartPage />} />
          </Switch>
        )}
      </Router>
    </AuthContext.Provider>
    </GettingStartedContext.Provider>
  )
}

export default App
